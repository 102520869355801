import React from 'react';
import css from './ListingPage.module.css';
import { IconCheckmark } from '../../components';
import { isLocaleGreek } from '../../util/genericHelpers';

function SectionMoreInfo(props) {
  const { intl, publicData } = props;
  const moreInfoTitle = intl.formatMessage({ id: 'ListingPage.moreInfoTitle' });

  const educationOrExperienceInUSAMessage = intl.formatMessage({
    id: 'DoctorOnboardingForm.educationOrExperienceInUSAMessage',
  });
  const educationOrExperienceInUKMessage = intl.formatMessage({
    id: 'DoctorOnboardingForm.educationOrExperienceInUKMessage',
  });
  const educationOrExperienceInGermanyMessage = intl.formatMessage({
    id: 'DoctorOnboardingForm.educationOrExperienceInGermanyMessage',
  });

  const educationOrExperienceInUSA = publicData?.educationOrExperienceInUSA
    ? isLocaleGreek()
      ? educationOrExperienceInUSAMessage
      : 'Education or Experience in USA'
    : null;

  const educationOrExperienceInUK = publicData?.educationOrExperienceInUK
    ? isLocaleGreek()
      ? educationOrExperienceInUKMessage
      : 'Education or Experience in UK'
    : null;

  const educationOrExperienceInGermany = publicData?.educationOrExperienceInGermany
    ? isLocaleGreek()
      ? educationOrExperienceInGermanyMessage
      : 'Education or Experience in Germany'
    : null;

  return (
    <div className={css.moreInfo}>
      <h2>{moreInfoTitle}</h2>
      {educationOrExperienceInUSA ? (
        <div className={css.more}>
          <IconCheckmark /> {educationOrExperienceInUSA}
        </div>
      ) : null}
      {educationOrExperienceInUK ? (
        <div className={css.more}>
          <IconCheckmark /> {educationOrExperienceInUK}
        </div>
      ) : null}
      {educationOrExperienceInGermany ? (
        <div className={css.more}>
          <IconCheckmark /> {educationOrExperienceInGermany}
        </div>
      ) : null}
    </div>
  );
}

export default SectionMoreInfo;
