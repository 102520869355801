import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconEducation = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="40"
      height="40"
      fill="#0F0F0F"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <metadata>
          {' '}
          <sfw xmlns="&amp;ns_sfw;">
            {' '}
            <slices> </slices>{' '}
            <slicesourcebounds width="505" height="984" bottomleftorigin="true" x="0" y="-552">
              {' '}
            </slicesourcebounds>{' '}
          </sfw>{' '}
        </metadata>{' '}
        <g>
          {' '}
          <g>
            {' '}
            <g>
              {' '}
              <path
                fill="#0F0F0F"
                d="M12,14c-0.1,0-0.2,0-0.3-0.1l-11-4C0.3,9.8,0,9.4,0,9s0.3-0.8,0.7-0.9l11-4c0.2-0.1,0.5-0.1,0.7,0l11,4 C23.7,8.2,24,8.6,24,9s-0.3,0.8-0.7,0.9l-11,4C12.2,14,12.1,14,12,14z M3.9,9l8.1,2.9L20.1,9L12,6.1L3.9,9z"
              ></path>{' '}
            </g>{' '}
          </g>{' '}
          <g>
            {' '}
            <g>
              {' '}
              <path
                fill="#0F0F0F"
                d="M19,20h-7c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c-0.1-2.6-0.7-4.8-1.7-6.4c-0.3-0.5-0.1-1.1,0.3-1.4c0.5-0.3,1.1-0.1,1.4,0.3 c1.3,2.1,2,5,2,8.4C20,19.6,19.6,20,19,20z"
              ></path>{' '}
            </g>{' '}
          </g>{' '}
          <g>
            {' '}
            <g>
              {' '}
              <path
                fill="#0F0F0F"
                d="M12,20H5c-0.6,0-1-0.4-1-1c0-3.4,0.7-6.3,2-8.4c0.3-0.5,0.9-0.6,1.4-0.3c0.5,0.3,0.6,0.9,0.3,1.4c-1,1.6-1.6,3.8-1.7,6.4 h6c0.6,0,1,0.4,1,1S12.6,20,12,20z"
              ></path>{' '}
            </g>{' '}
          </g>{' '}
          <g>
            {' '}
            <g>
              {' '}
              <path
                fill="#0F0F0F"
                d="M22,20c-0.6,0-1-0.4-1-1V9c0-0.6,0.4-1,1-1s1,0.4,1,1v10C23,19.6,22.6,20,22,20z"
              ></path>{' '}
            </g>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};

IconEducation.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconEducation.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconEducation;
